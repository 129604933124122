<template>
    <div class="row g-5 g-xl-8 mb-5 mb-xl-0" v-if="statistic.general">
        <div class="col-xl-4">
            <div class="card bg-body card-xl-stretch mb-xl-8">
                <div class="card-body">
                    <span class="svg-icon svg-icon-primary svg-icon-3x">
                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                    </span>
                    <div class="text-gray-900 fw-bolder fs-2 mb-2 mt-5">{{ statistic.general.video_count }}</div>
                    <div class="fw-bold text-gray-400">{{ $t('pages.dashboard.statistic.general.videoCount')}}</div>
                </div>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="card bg-body card-xl-stretch mb-xl-8">
                <div class="card-body">
                    <span class="svg-icon svg-icon-primary svg-icon-3x">
                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                    </span>
                    <div class="text-gray-900 fw-bolder fs-2 mb-2 mt-5">{{ statistic.general.hls_count }}</div>
                    <div class="fw-bold text-gray-400">{{ $t('pages.dashboard.statistic.general.hlsCount')}}</div>
                </div>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="card bg-body card-xl-stretch mb-xl-8">
                <div class="card-body">
                    <span class="svg-icon svg-icon-primary svg-icon-3x">
                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                    </span>
                    <div class="text-gray-900 fw-bolder fs-2 mb-2 mt-5">{{ statistic.general.video_group_count }}</div>
                    <div class="fw-bold text-gray-400">{{ $t('pages.dashboard.statistic.general.videoGroupCount')}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row g-5 g-xl-8" v-if="statistic.storage_usages && statistic.storage_usages.length">
        <div class="col-xl-4" v-for="(storageUsage, storageUsageIndex) in statistic.storage_usages" :key="storageUsageIndex">
            <div class="card bgi-no-repeat card-xl-stretch mb-xl-8">
                <div class="card-body">
                    <div class="card-title d-flex align-items-center justify-content-between">
                        <span class="fw-bolder text-muted fs-4">
                            {{ storageUsage.storage.name }}
                        </span>
                        <a v-on:click="showUsedStatistic(storageUsage)" class="cursor-pointer">
                            <span class="svg-icon svg-icon-primary svg-icon-2qx">
                                <inline-svg src="/media/icons/duotune/general/gen045.svg"/>
                            </span>
                        </a>
                    </div>

                    <div class="row">
                        <div class="col-md-6 my-6">
                            <b>{{ $t('common.used') }}:</b> {{ storageUsage.total_usage_detail.text }}
                        </div>

                        <div class="col-md-6 my-6">
                            <b>{{ $t('common.total') }}:</b> {{ storageUsage.user_storage_quota ? storageUsage.user_storage_quota.text : storageUsage.storage.size_detail.text }}
                        </div>
                    </div>

                    <el-progress :text-inside="true" :stroke-width="20" :percentage="storageUsage.total_usage_percentage"/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_used_storage" ref="usedStorageModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ $t('pages.dashboard.usedStatistic.title') }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div v-if="!Object.keys(usedStatisticDetail).length" class="alert alert-warning mb-0">
                        {{  $t('pages.dashboard.usedStatistic.noDataDescription') }}
                    </div>
                    <div v-else>
                        <div v-for="(statistic, statisticKey, statisticIndex) in usedStatisticDetail" :key="statisticIndex" class="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">
                            <span class="fs-6 text-gray-700 fw-bold">{{ statistic.text }}</span>
                            <div class="fw-semibold text-gray-400">{{ $t('pages.dashboard.usedStatistic.type.' + statisticKey) }}</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t('btn.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "dashboard",
    data(){
        return {
            usedStatisticDetail: {}
        }
    },
    computed: {
        statistic() {
            return this.$store.state.statistic.table.data;
        },
    },
    mounted(){
        this.setCurrentPageTitle(this.$t('menu.dashboard'));
        this.$store.dispatch('statistic/get', {});
    },
    methods: {
        showUsedStatistic(usedStorage){
            this.usedStatisticDetail = usedStorage.usage_statistics_detail ?? {};
            this.showModal(this.$refs.usedStorageModal);
        }
    }
}
</script>